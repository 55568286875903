import { createClient } from 'viem'
import { cookieStorage, createConfig, createStorage, http } from 'wagmi'
import { injected, walletConnect } from 'wagmi/connectors'
import { CHAINS } from 'constants/network'
import { env } from 'env.client'

export const wagmiConfig = createConfig({
  connectors: [
    injected({ target: 'metaMask' }),
    walletConnect({
      projectId: env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
    }),
  ],
  chains: [CHAINS.zircuit, CHAINS.l1],
  // *: https://wagmi.sh/react/guides/ssr
  ssr: true,
  storage: createStorage({
    key: 'wagmi.store.zkr-bridge',
    storage: cookieStorage,
  }),
  client({ chain }) {
    return createClient({ chain, transport: http() })
  },
})
