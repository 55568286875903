import { DECIMALS } from 'constants/common'

export const getFormattedNumber = (value: string | number) =>
  Number(value).toLocaleString(undefined, { maximumFractionDigits: 20 })

export const weiToEth = (value: string | number, decimals?: string | number) =>
  Number(value) / 10 ** Number(decimals ?? DECIMALS)

export const calculateGasFees = ({ gasUsed = 0, gasPrice = 0 }) => weiToEth(gasUsed * gasPrice)

export const formatStringSvgToImageSrc = (svgString: string) =>
  `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`
